/* 📂 RepMetricsTable.css */
.metrics-table-container {
    width: 100%;
    overflow-x: auto;
}

.metrics-table {
    width: 100%;
    border-collapse: collapse;
}

.metrics-table th, .metrics-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

.metrics-table thead th {
    background-color: #f4f4f4;
}

.priority-rep {
    background-color: #ffdddd; /* Light Red */
}

.secondary-rep {
    background-color: #ddffdd; /* Light Green */
}

.other-rep {
    background-color: #f0f0ff; /* Light Blue */
}
